<template>
  <div class="calendar">
    <div class="calendar__head">
      <div class="calendar__add-event" v-if="$can('manage', 'events')">
        <vs-button type="flat" icon="add" @click="addEventHandler">
          Add event
        </vs-button>
      </div>
    </div>
    <CalendarComponent
      @changeView="changeView"
      @createEvent="createEventHandler"
      @taskSaved="taskSavedHandler"
    />
    <vs-popup
      class="popup__event"
      classContent=""
      title="Add Event"
      :active.sync="popupActive"
      @close="closePopupHandle"
    >
      <TaskForm
        v-if="popupActive"
        place_type="event"
        :date_start="eventDateStart"
        @taskSaved="taskSavedHandler"
      />
    </vs-popup>
  </div>
</template>

<script>
// import TimeTableComponent from "@/components/timetable/timetable";
// import LessonForm from "@/components/lessons/tutorLessonForm";
import TaskForm from '@/components/tasks/taskForm'
import CalendarComponent from '../components/events/calendarComponent.vue'

export default {
  name: 'Calendar',
  title: 'SE-Calendar',
  data() {
    return {
      minDate: this.$moment().format('s'),
      maxDate: this.$moment()
        .add(1, 'month')
        .format('s'),
      popupActive: false,
      eventDateStart: new Date().getTime(),
    }
  },
  computed: {},
  components: {
    TaskForm,
    CalendarComponent,
  },
  methods: {
    getEvents: function() {
      const filterData = {
        start: this.minDate,
        end: this.maxDate,
      }
      this.$store.dispatch('events/getEventsAction', filterData)
    },
    changeView: function({ minDate, maxDate }) {
      console.log(minDate)
      this.minDate = minDate
      this.maxDate = maxDate
      this.getEvents()
    },
    addEventHandler: function() {
      this.popupActive = true
    },
    closePopupHandle: function() {
      this.popupActive = false
    },
    createEventHandler: function(event) {
      let date = new Date()
      date.setDate(event.date.getDate())
      date.setMonth(event.date.getMonth())
      date.setFullYear(event.date.getFullYear())
      this.eventDateStart = date.getTime()
      this.popupActive = true
    },
    taskSavedHandler: function() {
      this.getEvents()
      this.closePopupHandle()
    },
  },
  created: async function() {
    await this.$store.dispatch('groups/getGroupTypesAction')
    // if (!this.currentSchoolYear.id) {
    //   await this.$store.dispatch('years/getYearsAction')
    // }
    // const data = {
    //   schoolyear_id: this.currentSchoolYear.id,
    // }
    // await this.$store.dispatch('groups/getGroupsAction', { data })

    await this.$store.dispatch('teachers/getTeachersAction')
    await this.$store.dispatch('rooms/getRoomsAction')
    await this.$store.dispatch('students/getStudentsAction')

    this.getEvents()
  },
}
</script>
<style lang="scss">
.timtable__head {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}
.calendar__add-event .vs-button {
  float: none;
}
</style>
