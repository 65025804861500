<template>
  <div>
    <FullCalendar :options="config" @datesSet="this.changeView" ref="calendar">
      <template v-slot:eventContent="arg">
        <span
          v-if="arg.event.extendedProps.type == 'student_birthday'"
          :title="
            arg.event.extendedProps.items
              ? arg.event.extendedProps.items.map(b => b.name).join('\n')
              : ''
          "
        >
          🎉 {{ arg.event.extendedProps.items.length }}
        </span>
        <span v-else-if="arg.event.extendedProps.type == 'teacher_birthday'">
          <span v-for="i in arg.event.extendedProps.items" :key="i.id">
            🎉
            <vs-avatar
              :src="
                i.options && i.options.avatar
                  ? `${proxy}/static/files/${i.options.avatar}`
                  : undefined
              "
              size="14px"
            ></vs-avatar
            >{{ i.name }}
          </span>
        </span>
        <span v-else class="custom-event-span">
          <vs-icon
            icon="circle"
            v-if="!arg.event.allDay"
            :color="arg.event.backgroundColor"
            size="10px"
          ></vs-icon>
          <span>{{ arg.timeText }}</span>
          <span class="event-title"
            ><b>{{ arg.event.title }}</b></span
          >
        </span>
      </template>
    </FullCalendar>
    <div v-if="viewPopupActive">
      <vs-popup
        class="form-popup teacher-tasks-popup-view"
        classContent="task__popup-content-edit-view"
        :title="`Event #${selectedTask.id || ''}`"
        :active.sync="viewPopupActive"
        @close="closeTaskPopupHandler"
      >
        <task-view
          :id="selectedTask.id"
          :taskUnsavedObject="selectedTask"
          @editTask="editTaskHandler"
          @taskSaved="taskUpdateHandler"
        />
      </vs-popup>
    </div>
    <div v-if="editPopupActive">
      <vs-popup
        class="form-popup teacher-tasks-popup-edit"
        classContent="task__popup-content-edit"
        :title="selectedTask ? `Edit task #${selectedTask.id}` : 'Create task'"
        :active.sync="editPopupActive"
      >
        <task-form
          @taskSaved="taskUpdateHandler"
          :task="selectedTask"
          place_type="event"
        />
      </vs-popup>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import taskView from '../tasks/taskView.vue'
import taskForm from '../tasks/taskForm.vue'

export default {
  data() {
    return {
      startDate: null,
      endDate: null,
      viewPopupActive: false,
      selectedTask: null,
      editPopupActive: false,
      proxy: process.env.VUE_APP_PROXY,
    }
  },
  computed: {
    ...mapState({
      events: state => state.events.events,
    }),
    config() {
      return {
        events: this.events,
        locale: 'en',
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: 'dayGridMonth',
        nowIndicator: true,
        allDaySlot: true,
        editable: this.$can('manage', 'lessons'),
        datesSet: this.changeView,
        eventOrder: ['date_start'],
        eventBorderColor: '#bbb',
        eventClick: this.eventSelected,
        dateClick: this.eventCreated,
        slotEventOverlap: false,
        eventDidMount: this.eventsSet,
        dayCellDidMount: this.dayCellSet,
        progressiveEventRendering: true,
        firstDay: 1,
        eventTimeFormat: {
          hour: 'numeric',
          minute: '2-digit',
          meridiem: false,
          hour12: false,
        },
        displayEventEnd: true,
        timeZone: 'local',
      }
    },
  },
  components: {
    FullCalendar, // make the <FullCalendar> tag available
    taskView,
    taskForm,
  },
  methods: {
    changeView: function(view) {
      if (
        this.startDate == null ||
        this.startDate.getTime() != view.start.getTime()
      ) {
        if (
          this.endDate == null ||
          this.endDate.getTime() != view.end.getTime()
        ) {
          this.startDate = view.start
          this.endDate = view.end
          this.$emit('changeView', {
            minDate: view.start.getTime() / 1000,
            maxDate: view.end.getTime() / 1000,
          })
        }
      }
    },
    eventSelected: function(info) {
      if (info.event.extendedProps.type.includes('_birthday')) {
        return
      }
      this.selectedTask = info.event.extendedProps
      this.viewPopupActive = true
    },
    eventCreated: function(info) {
      this.$emit('createEvent', info)
    },
    closeTaskPopupHandler: function() {
      this.viewPopupActive = false
    },
    editTaskHandler: function() {
      this.editPopupActive = true
    },
    taskUpdateHandler: function(event) {
      this.selectedTask = event
      this.$emit('taskSaved')
    },
    eventsSet: function(info) {
      if (info.event.extendedProps.type.includes('student_birthday')) {
        info.el.classList.add('event-birthday', 'student-birthday')
      } else if (info.event.extendedProps.type.includes('teacher_birthday')) {
        info.el.classList.add('event-birthday', 'teacher-birthday')
      }
    },
  },
}
</script>

<style lang="scss">
.event-birthday {
  position: absolute;
  top: -1.7rem;
  right: 1.5rem;
  padding: 3px;
  opacity: 0.7;
  &.teacher-birthday {
    padding: 0px;
    right: 4rem;
  }
}
.custom-event-span {
  display: flex;
  align-items: center;
  gap: 3px;
  .event-title {
    padding-left: 5px;
  }
}
</style>
